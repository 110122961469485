import React, { useContext } from "react";
import { FormItem, InputTextFields, } from "../../components/InputField.styled";
import PropTypes from "prop-types";
import { RegisterStepContainer } from "../../components/Register.styled";
import { NewCitizenContext } from "../NewCitizenContext";
import NumericInput from "components/form/fields/NumericInput";

const OtherInformation = ({ children }) => {
  const {
    stateDispatchMain,
    state,
  } = useContext(NewCitizenContext);

  // Computation is for every kilo 20  pesos.
  const totalAicsSupport = `₱ ${((state.citizenDetails?.rice_kg || 0) * 20).toLocaleString()}`;

  return (
    <RegisterStepContainer>
      <FormItem name="rice_kg" label="Kilos of Palay">
        <NumericInput
          defaultValue={0}
          name="rice_kg"
          onChange={(value) => {
            stateDispatchMain({
              key: "citizenDetails",
              payload: {
                ...state.citizenDetails,
                rice_kg: value,
              },
            })
          }
          }
        />
      </FormItem>
      <FormItem label="Total AICS Support">
        <InputTextFields
          name="total_aics_support"
          value={totalAicsSupport || 0}
          readOnly
        />
      </FormItem>
      {children}
    </RegisterStepContainer>
  );
};

OtherInformation.propTypes = {
  formData: PropTypes.any,
  onInputChange: PropTypes.func,
  errors: PropTypes.any,
};

export default OtherInformation;
