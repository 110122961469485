import React, { useState, useEffect, useReducer, createContext } from "react";
import { Form } from "antd";
import genderOptions from "../../../jsonLib/gender_options.json";
import allProvince from "../../../jsonLib/allProvince.json";
import allMunicipalities from "../../../jsonLib/allMunicipalities.json";
import {
  getBarangayOption,
  verifyCitizen,
  sendOTP,
  validateOTP,
  uploadCitizenPhoto,
  registerCitizen,
  generateIDCard,
} from "../../../services/public_registration.service";
import Swal from "sweetalert2";
import moment from "moment";
import { useRegisterContext } from "../Context";
import { containsSpecialFontCharacters } from "utils";

export const NewCitizenContext = createContext({});

const defaultState = {
  citizenDetails: {
    first_name: "",
    last_name: "",
    middle_name: "",
    name_extension: "",
    birthdate_year: "",
    birthdate_month: "",
    birthdate_day: "",
    gender: null,
    phone_number: "",
    is_voter: null,
    barangay: null,
    address: "",
    municipality: null,
    email: "",
    province: null,
    profile: "",
    principal: "",
    is_verified: false,
    card_number: "",
    // farmer fields
    rice_kg: 0,
  },
  socialServiceDetails: {
    customer: null,
    service: null,
    status: "pending",
    value_requested: "0",
    has_representative: null,
    other_details: {},
  },
};

const defaultStateFn = key => {
  return key ? defaultState[key] : defaultState;
};

const stateDispatchReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_CITIZEN_DETAILS":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET":
      return defaultState();

    default: {
      if (action.key) {
        if (typeof action.payload === "object") {
          return {
            ...state,
            [action.key]: {
              ...state[action.key],
              ...action.payload,
            },
          };
        }

        return {
          ...state,
          [action.key]: action.payload,
        };
      }

      return state;
    }
  }
};

const NewCitizenProvider = ({ children }) => {
  const [state, stateDispatch] = useReducer(stateDispatchReducer, defaultStateFn());
  const { userCode } = useRegisterContext();

  const [provinceOptions, setProvinceOptions] = useState();
  const [selectedProvince, setSelectedProvince] = useState();
  const [municipalityOptions, setMunicipalityOptions] = useState();
  const [selectedMunicipality, setSelectedMunicipality] = useState();
  const [barangayOption, setBarangayOptions] = useState();
  const [agencyOptions, setAgencyOptions] = useState();
  const [selectedAgency, setSelectedAgency] = useState();
  const [selectedSocialService, setSelectedSocialService] = useState();
  const [socialServiceOptions, setSocialServiceOptions] = useState();
  const [filteredSocialServiceOptions, setFilteredSocialServiceOptions] = useState();
  const [hasRepresentative, setHasRepresentative] = useState();
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [messageID, setMessageID] = useState();
  const [otp, setOTP] = useState();
  const [ATCOptions, setATCOptions] = useState();
  const [IDCard, setIDCard] = useState({ url: '', filename: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [agreement, setAgreement] = useState(false);

  const [form] = Form.useForm();

  function formatPhoneNumber(value) {
    // format the input value as a Philippine phone number
    if (!value) return "";
    value = value.toString();
    if (value.startsWith("0")) return value.replace(/^0/, "63");
    if (value.startsWith("63")) return value;

    // setVal(`63${value}`);
    return `63${value}`;
  }

  function parsePhoneNumber(value) {
    // parse the input value as a number
    if (!value) return 0;
    return value.toString().replace(/^(0|63)/, "");
  }

  const representativeOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  useEffect(() => {
    const filteredProvince = allProvince.map(province => ({
      ...province,
      label: province?.name,
      value: province?.id,
    }));

    setProvinceOptions(filteredProvince);
  }, []);

  useEffect(() => {
    if (selectedProvince) {
      const filteredMunicipality = allMunicipalities
        .filter(municipality => municipality?.province?.id === selectedProvince)
        .map(municipality => ({
          ...municipality,
          label: municipality?.name,
          value: municipality?.id,
        }));
      form.setFieldsValue({ municipality: undefined });
      setSelectedMunicipality(null);
      setMunicipalityOptions(filteredMunicipality);
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedMunicipality) {
      setIsLoading(true);
      setBarangayOptions([]);
      const fetchBarangay = async () => {
        const barangayOption = await getBarangayOption(selectedMunicipality);
        const filteredBarangayOption = barangayOption.results.map(barangay => ({
          ...barangay,
          label: barangay?.name,
          value: barangay?.id,
        }));
        setIsLoading(false);
        setBarangayOptions(filteredBarangayOption);
      };

      fetchBarangay().catch(console.error);
    }
  }, [selectedMunicipality]);

  // changed drop down option for social service
  useEffect(() => {
    if (selectedAgency) {
      setSelectedSocialService(null);
      const filteredOption = socialServiceOptions?.filter(
        socialService => socialService?.agency?.id === selectedAgency,
      );
      setFilteredSocialServiceOptions(filteredOption);
    }
  }, [selectedAgency]);

  useEffect(() => {
    if (isNumberValid) {
      Swal.fire({
        title: "Oops!",
        text: "Ang numero na ito ay ginamit na ng tatlong beses. Mangyaring maglagay ng wastong Mobile Number.",
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: "Exit",
        showConfirmButton: false,
        width: "80%",
      });
    }
  }, [isNumberValid]);

  const checkUserExist = async () => {
    // check if name contains other text characters
    const containsSpecialFonts = [
      'first_name',
      'last_name',
      'name_extension'
    ].some((fieldName) => containsSpecialFontCharacters(state.citizenDetails[fieldName]));
    if (containsSpecialFonts) {
      Swal.fire({
        title: "Oops!",
        text: "Invalid name!",
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: "Exit",
        showConfirmButton: false,
        width: "80%",
      });
      return false;
    }

    const isUserExist = await verifyCitizen(
      state.citizenDetails.first_name,
      state.citizenDetails.last_name,
      state.citizenDetails.suffix,
      moment(
        `${state.citizenDetails.birthdate_year}-${state.citizenDetails.birthdate_month}-${state.citizenDetails.birthdate_day}`,
        "YYYY-MM-DD",
        false,
      ).format("YYYY-MM-DD"),
    );
    if (isUserExist) {
      Swal.fire({
        title: "Oops!",
        text: "Mayroon ka nang record.",
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: "Exit",
        showConfirmButton: false,
        width: "80%",
      });
      return false;
    }
  };

  const sendOTPMobile = async () => {
    const otp = await sendOTP(state.citizenDetails.phone_number);
    setMessageID(otp?.message_id);
  };

  const verifyOTP = async () => {
    const isValidOTP = await validateOTP(messageID, otp);
    if (!isValidOTP || otp.length < 6 || otp === undefined) {
      Swal.fire({
        title: "Oops!",
        text: "Mali ang iyong nilagay na OTP",
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: "Exit",
        showConfirmButton: false,
        width: "80%",
      });
    }
    return isValidOTP;
  };

  const uploadPhoto = async () => {
    const isUpload = await uploadCitizenPhoto(state.citizenDetails.profile);
    return isUpload;
  };

  const transformCitizenDetailsToParams = () => {
    const citizenDetailsToParam = { ...state.citizenDetails };
    delete citizenDetailsToParam.birthdate_year;
    delete citizenDetailsToParam.birthdate_month;
    delete citizenDetailsToParam.birthdate_day;

    citizenDetailsToParam.birth_date = moment(
      `${state.citizenDetails.birthdate_year}-${state.citizenDetails.birthdate_month}-${state.citizenDetails.birthdate_day}`,
      "YYYY-MM-DD",
      false,
    ).format("YYYY-MM-DD");
    citizenDetailsToParam.card_number = userCode.trim();

    // should not be empty string params; change to `null`
    [
      'fb_profile_link',
    ].forEach((fieldName) => {
      if (!citizenDetailsToParam?.[fieldName]) {
        citizenDetailsToParam[fieldName] = null
      }
    });

    return citizenDetailsToParam;
  };

  const registerCitizenDetails = async () => {
    if (state.citizenDetails.profile) {
      const params = transformCitizenDetailsToParams();
      const register = await registerCitizen(params);
      if (register?.id) {
        const res = await generateIDCard(register.id);
        const filename = `${register.id}.png`;
        setIDCard({ url: URL.createObjectURL(res), filename });
        return true;
      } else {
        Swal.fire({
          title: "Oops!",
          text: "There was an error! Please contact the administrator",
          allowOutsideClick: true,
          showCancelButton: true,
          cancelButtonText: "Exit",
          showConfirmButton: false,
          width: "80%",
        });
      }
      return false;
    } else {
      Swal.fire({
        title: "Oops!",
        text: "Kinakailangan mag-upload ng iyong litrato",
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: "Exit",
        showConfirmButton: false,
        width: "80%",
      });
      return false;
    }
  };

  const downloadCitizenID = () => {
    const link = document.createElement("a");
    link.href = IDCard.url;
    link.download = IDCard.filename;
    link.click();
  };

  const payload = {
    genderOptions,
    state,
    stateDispatchMain: stateDispatch,
    provinceOptions,
    municipalityOptions,
    setSelectedProvince,
    setSelectedMunicipality,
    barangayOption,
    selectedProvince,
    selectedMunicipality,
    agencyOptions,
    representativeOptions,
    formatPhoneNumber,
    parsePhoneNumber,
    setSelectedAgency,
    selectedSocialService,
    filteredSocialServiceOptions,
    hasRepresentative,
    setHasRepresentative,
    setSelectedSocialService,
    setIsNumberValid,
    isNumberValid,
    checkUserExist,
    sendOTPMobile,
    messageID,
    setOTP,
    verifyOTP,
    uploadPhoto,
    registerCitizenDetails,
    ATCOptions,
    IDCard,
    downloadCitizenID,
    isLoading,
    agreement,
    setAgreement,
  };

  return <NewCitizenContext.Provider value={payload}>{children}</NewCitizenContext.Provider>;
};

export default NewCitizenProvider;
