import styled from "styled-components";
import { Input, DatePicker, Select, Form, InputNumber } from "antd";

const FormItem = styled(Form.Item)`
  width: 100%;

  input {
    height: 3rem;
    padding: 0 15px;
  }

  .ant-form-item-label {
    font-size: 14px;
    z-index: 1;
    width: auto;
    flex-basis: auto !important;
    padding: 0;
  }
  &.FormItem-Birthdate {
    & > .ant-form-item-label {
      padding-bottom: 8px;
    }
  }

  .ant-form-item-label > label {
    align-items: center;
    color: #5F5D70;
    font-size: 14px;
    background-color: white;
    font-weight: 600;
  }
  
  .ant-radio-group {
    display: flex;
    gap: 16px;
    .ant-radio-button-wrapper {
      text-align: center;
      flex: 1 1 100%;
      border-width: 1px;
      border-radius: 16px;
      height: 48px;
      line-height: 48px;
      span {
        font-weight: 600;
      }
      &:not(:first-child):before {
        display: none;
      }
      &.ant-radio-button-wrapper-checked {
        background-color: #E7B110;
        border-color: #E7B110;
        span {
          color: #FFFFFF;
        }
      }
    }

    @media only screen and (min-width: 766px) {
      margin-top: 20px;
    }
  }

  @media only screen and (min-width: 766px) {
    display: block;

    .ant-form-item-label {
      top: 17.5px;
      font-size: 14px;
      z-index: 1;
    }

    .ant-form-item-label > label {
      position: relative;
      display: inline-flex;
      align-items: center;
      max-width: 100%;
      height: 32px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
    }
  }
`;

const InputTextFields = styled(Input)`
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;

  .ant-input {
    position: relative;
    padding: 8px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;

    &:hover {
      border-color: #1890ff;
    }

    &:focus {
      border-color: #1890ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    &.ant-input-disabled {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.65);
      cursor: not-allowed;
      border-color: #d9d9d9;
    }
  }
`;

const InputPhoneNumberField = styled(InputNumber)`
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
`;

const InputNumberField = styled(InputNumber)`
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
`;

const InputSelectField = styled(Select)`
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
  padding: 10px 0px;

  .ant-select-selector {
    border: none !important;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border: none !important;
  }
`;

/** PersonalInformation.js **/
const BirthdateSelectContainer = styled.div`
  display: flex;
  gap: 1rem;
  border-radius: 8px;
  background: #fff;
  padding: 10px 0;

  .ant-row {
    margin-top: -1.5rem;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 768px) {
    width: 100%;
    justify-content: space-between;

    .personal-info-date-select {
      margin-top: 0;
    }
  }
`;

/** PersonalInformation.js **/
const InputDateSelectField = styled(Select)`
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
  padding: 10px 0px;

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    z-index: 1;
  }
`;

const InputDateField = styled(DatePicker)`
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(207, 207, 213, 0.5);
  background: #fff;
  padding: 10px;
`;

export {
  FormItem,
  InputTextFields,
  InputPhoneNumberField,
  InputSelectField,
  InputDateField,
  BirthdateSelectContainer,
  InputDateSelectField,
  InputNumberField,
};
