import React, { useContext } from "react";
import { RegisterContainer, StepHeader, RegisterLogo } from "../components/Register.styled";
import WizardForm from "../../../components/form/wizard";
import PersonalInformation from "./steps/PersonalInformation";
import PersonalAddress from "./steps/PersonalAddress";
import OTPVerification from "./steps/OTPVerification";
import UploadImage from "./steps/UploadImage";
import IDCard from "./steps/IDCard";
import { NewCitizenContext } from "./NewCitizenContext";
import CodeLogout from "../CodeForm/CodeLogout";
import OtherInformation from "./steps/OtherInformation";

const NewCitizensComponent = () => {
  const { checkUserExist, sendOTPMobile, verifyOTP, registerCitizenDetails } = useContext(NewCitizenContext);

  const steps = [
    {
      title: "Personal Information",
      component: PersonalInformation,
      hideBackBtn: true,
      asyncValidator: checkUserExist,
    },
    {
      title: "Address",
      component: PersonalAddress,
    },
    {
      title: "Other Information",
      component: OtherInformation,
      asyncFn: sendOTPMobile,
    },
    {
      title: "Verification",
      centerTitle: true,
      component: OTPVerification,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: verifyOTP,
    },
    {
      title: "Upload Image",
      centerTitle: true,
      component: UploadImage,
      hideBackBtn: false,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: registerCitizenDetails,
    },
    {
      title: "Success",
      centerTitle: true,
      component: IDCard,
      hideBackBtn: true,
      hideWizardSteps: true,
      hideNextBtn: true,
      hideBottom: true,
    },
  ];

  return (
    <RegisterContainer>
      <CodeLogout />
      <StepHeader>
        <RegisterLogo src="static/images/logo-banner.png" alt="farmer logo" />
      </StepHeader>
      <WizardForm steps={steps} />
    </RegisterContainer>
  );
};

export default NewCitizensComponent;
